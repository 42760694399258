const awsConfig = {
  Auth: {
    identityPoolId: 'eu-west-1:c8ecad15-1b99-4015-a340-73c4186e16aa', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'eu-west-1', // REQUIRED - Amazon Cognito Region
    userPoolId: 'eu-west-1_vdQtQbKBN', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '2q0amgpra5sugd6tvhtct6t7ts', //OPTIONAL - Amazon Cognito Web Client ID
  },
  Storage: {
    AWSS3: {
      bucket: 'sandbox-referencecatalog-s3-bucket',
      region: 'eu-west-1', //OPTIONAL -  Amazon service region
    }
  },
  region: 'eu-west-1',
};

export default awsConfig;